//
// To make future updates easier consider overriding the global variables from _variables.bootstrap.scss and _variables.custom.scss for current demo in this file.
// Note that this file is included first and variables defined in _variables.bootstrap.scss and _variables.custom.scss
// are not accessible in this file but you can override any global variable as shown below:
//

// Theme colors
// Override primary color variants
/* CAMBIAR COLOR */ /* --> PALETA DE COLOR PRINCIPALES */
$primary: #cc02d6; // Bootstrap variable
$primary-hover: rgb(240, 152, 245); // Custom variable
$primary-light: #eb9bf0; // Custom variable
$primary-inverse: #fffbff; // Custom variable
